var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leaderboard-container"},[_c('div',{staticClass:"header-top pb-2 ml-3 mr-3"},[_c('span',{staticClass:"route-title-first"},[_vm._v(_vm._s(_vm.$t('settingsRoute.title')))])]),_c('div',{staticClass:"tab-container"},_vm._l((_vm.tabNames),function(tab){return _c('div',{key:tab,class:{'tab-button-active': _vm.selectedTab === tab},on:{"click":function($event){return _vm.toggleTab(tab)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === _vm.$t('settingsRoute.generalTab')),expression:"selectedTab === $t('settingsRoute.generalTab')"}],staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.saveProfile.apply(null, arguments)}}},[_c('div',{staticClass:"guideline"},[_c('span',[_vm._v(_vm._s(_vm.$t('settingsRoute.guideline')))])]),_c('div',{staticClass:"form-inputs-container"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.username'))+" * ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control",class:{
                        'is-valid': _vm.form.username,
                        'is-invalid': _vm.form.username === ''
                    },attrs:{"type":"text","name":"username","trim":""},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value)}}})]),_c('div',{staticClass:"passwords-container"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.newPassword'))+" * ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],staticClass:"form-control",class:{
                            'is-valid': _vm.form.newPassword,
                            'is-invalid': _vm.form.newPassword === ''
                        },attrs:{"type":"password","name":"New Password","trim":""},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "newPassword", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.confirmPassword'))+" * ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmPassword),expression:"form.confirmPassword"}],staticClass:"form-control",class:{
                            'is-valid': _vm.form.confirmPassword,
                            'is-invalid': _vm.form.confirmPassword !== _vm.form.newPassword
                        },attrs:{"type":"password","name":"Confirm Password","trim":""},domProps:{"value":(_vm.form.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirmPassword", $event.target.value)}}})])])]),_c('button',{staticClass:"btn save-button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.save'))+" ")]),_c('div',{staticClass:"messages-container"},[(_vm.errorMessage)?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('b-form-checkbox',{attrs:{"size":"lg","switch":""},on:{"change":_vm.onNotificationChange},model:{value:(_vm.notificationSubscription),callback:function ($$v) {_vm.notificationSubscription=$$v},expression:"notificationSubscription"}},[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.notificationSubscription'))+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === _vm.$t('settingsRoute.arenaTab')),expression:"selectedTab === $t('settingsRoute.arenaTab')"}],staticClass:"form-container arena-form"},[_c('form',{staticClass:"arena-form-container",on:{"submit":function($event){$event.preventDefault();return _vm.saveArenaSettings.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.challengerId'))+" * ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.arenaForm.challengerId),expression:"arenaForm.challengerId"}],staticClass:"form-control",class:{
                        'is-valid': _vm.arenaForm.challengerId && !_vm.hasChallengerIdBadWords,
                        'is-invalid': _vm.arenaForm.challengerId === '' || _vm.hasChallengerIdBadWords
                    },attrs:{"type":"text","trim":""},domProps:{"value":(_vm.arenaForm.challengerId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.arenaForm, "challengerId", $event.target.value)}}})]),_c('button',{staticClass:"btn save-button",class:{ disabled: _vm.disableSaveChallengerIdButton },attrs:{"type":"submit","disabled":_vm.disableSaveChallengerIdButton}},[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.save'))+" ")]),_c('div',{staticClass:"messages-container"},[(_vm.arenaErrorMessage)?_c('span',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.arenaErrorMessage)+" ")]):_vm._e()])]),_c('b-form-checkbox',{attrs:{"size":"lg","switch":""},on:{"change":_vm.toggleArenaParticipation},model:{value:(_vm.arenaParticipation),callback:function ($$v) {_vm.arenaParticipation=$$v},expression:"arenaParticipation"}},[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.arenaParticipation'))+" ")]),_c('b-form-checkbox',{attrs:{"size":"lg","switch":"","disabled":!_vm.arenaParticipation},on:{"change":_vm.toggleArenaNotifications},model:{value:(_vm.arenaNotifications),callback:function ($$v) {_vm.arenaNotifications=$$v},expression:"arenaNotifications"}},[_vm._v(" "+_vm._s(_vm.$t('settingsRoute.notificationArena'))+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === _vm.$t('settingsRoute.clustersTab')),expression:"selectedTab === $t('settingsRoute.clustersTab')"}],staticClass:"form-container"},[_c('div',{staticClass:"cluster-container"},[_c('cluster-selection',{on:{"login":_vm.clusterSelected}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }