<template>
    <div class="cluster-selection">
        <div
            class="cluster-selection-container"
        >
            <div class="cluster-selection-title">
                {{ $t('clusterSelection.title') }}
            </div>
            <div class="cluster-selection-list">
                <div
                    v-for="cluster in availableClusters"
                    :key="cluster.id"
                    class="cluster-selection-item"
                    :class="{
                        'selected': isSelectedCluster(cluster.id)
                    }"
                    @click="selectCluster(cluster.id)"
                >
                    {{ getClusterName(cluster) }}
                </div>
            </div>
            <button
                :disabled="isContinueDisabled"
                class="custom-btn"
                :class="{'disabled': isContinueDisabled}"
                @click="login"
            >
                {{ $t('clusterSelection.continueText') }}
            </button>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ClusterSelection',
        data() {
            return {
                selectedCluster: null
            }
        },
        computed: {
            availableClusters() {
                return this.$store.getters.getAvailableClusters
            },
            isContinueDisabled() {
                return !this.selectedCluster
            },
            storedCluster() {
                return this.$store.getters.getSelectedCluster
            }
        },
        watch: {
            storedCluster: {
                immediate: true,
                handler(newVal) {
                    this.selectedCluster = newVal
                }
            }
        },
        methods: {
            getClusterName(cluster) {
                return cluster?.multilingualClusters?.[0]?.config.name
            },
            isSelectedCluster(clusterId) {
                return this.selectedCluster === clusterId
            },
            selectCluster(clusterId) {
                this.selectedCluster = clusterId
            },
            login() {
                this.$emit('login', this.selectedCluster)
            }
        }
    }
</script>

<style scoped>
.cluster-selection {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    border-radius: 1rem;
}

.cluster-selection-container {
    --base-color: #193674;

    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-rows: 15% 3fr 15%;
    grid-template-columns: 100%;
    color: var(--base-color);
    padding: 1rem;
}

.cluster-selection-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.cluster-selection-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    overflow-y: auto;
    max-height: 70%;
    width: 100%;
}

.cluster-selection-item {
    cursor: pointer;
    font-size: 1.25rem;
    width: max-content;
    position: relative;
}

.cluster-selection-item:hover,
.cluster-selection-item.selected {
    font-weight: 700;
}

.cluster-selection-item.selected::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--base-color);
}

.custom-btn {
    max-width: 420px;
    width: 80%;
    height: 60px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
    font-weight: 500;
    background: #193674;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
    border: none;
}

.custom-btn:hover:enabled {
    background: #f1b12b;
}

.custom-btn:active:enabled {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

@media screen and (min-width: 768px) {
    .cluster-selection-title {
        font-size: 1.75rem;
        font-weight: 700;
    }

    .custom-btn {
        font-size: 1.25rem;
    }
}
</style>
