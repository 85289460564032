<template>
    <div class="sidebar">
        <router-link to="/home">
            <b-img
                :src="branding.primaryLogoUrl"
                class="pt-2 logo"
                alt=" "
            />
        </router-link>
        <div class="underlay">
            <div class="avatar-container">
                <router-link
                    to="/edit-profile"
                    class="avatar-link"
                >
                    <avatar
                        :avatar="player.avatar || null"
                    />
                    <div class="edit-icon">
                        <edit-icon-svg />
                    </div>
                </router-link>
            </div>
            <div class="player-info">
                <span>{{ playerNickName }}</span>
            </div>
        </div>
        <div class="underlay">
            <div
                class="player-status"
            >
                <div class="player-points round-info">
                    <div class="value">{{ totalPoints }}</div>
                    <div class="title">{{ $t('sidebar.points') }}</div>
                </div>
                <div v-if="isLeaderboardEnabled" class="player-rank round-info">
                    <div class="value">{{ currentPlayerPosition }}</div>
                    <div class="title">{{ $t('sidebar.rank') }}</div>
                </div>
            </div>
        </div>
        <div
            v-if="isSidebarProgressInfoEnabled || showRewardProgressBar || showGoToRewardsButton"
            class="underlay"
        >
            <side-bar-progress-bar
                v-if="isSidebarProgressInfoEnabled"
                :title="$t('sidebar.missions')"
                :current-value="completedMissionCount"
                :total="totalMissionCount"
            />
            <side-bar-progress-bar
                v-if="isSidebarProgressInfoEnabled"
                :title="$t('sidebar.tasks')"
                :current-value="completedTasks"
                :total="totalTaskCount"
            />
            <side-bar-progress-bar
                v-if="showRewardProgressBar"
                :title="$t('sidebar.pointsForCoupon')"
                :current-value="playerAvailablePoints"
                :total="rewardNeededPoints"
            />
            <router-link
                v-if="showGoToRewardsButton"
                :to="{ name: 'rewards' }"
                class="go-to-rewards"
            >
                {{ $t('sidebar.goToRewards') }}
            </router-link>
        </div>
        <div
            v-if="isBadgesEnabled && playerBadges && playerBadges.length > 0"
            class="underlay"
        >
            <badges-component
                :player-badges="playerBadges"
                :current-total-badges="currentTotalBadges"
                :total-mission-count="totalMissionCount"
            />
        </div>

        <div class="terms-instructions-container ">
            <a
                v-if="branding.manualPdfUrl"
                :href="branding.manualPdfUrl"
                target="_blank"
                class="text-left instructions"
            >
                {{ $t('sidebar.instructions') }}
            </a>
            <span
                v-if="branding.termsPdfUrl && branding.manualPdfUrl"
                class="url-divider"
            > | </span>
            <a
                v-if="branding.termsPdfUrl"
                :href="branding.termsPdfUrl"
                target="_blank"
                class="text-left terms"
            >
                {{ $t('sidebar.terms') }}
            </a>
            <span
                v-if="branding.privacyPolicyPdfUrl"
                class="url-divider"
            > | </span>
            <a
                v-if="branding.privacyPolicyPdfUrl"
                :href="branding.privacyPolicyPdfUrl"
                target="_blank"
                class="text-left terms"
            >
                {{ $t('sidebar.privacy') }}
            </a>
            <span class="copyright">
                <div class="plain-text">
                    {{ $t('sidebar.poweredByMpass[0]') }}
                </div>
                <a href="https://mpass.gr" target="_blank">{{ $t('sidebar.poweredByMpass[1]') }}</a>
            </span>
        </div>
    </div>
</template>

<script>

    import Avatar from '@/components/Avatar'
    import EditIconSvg from '@/components/svg/EditIconSVG'
    import SideBarProgressBar from '@/components/SideBarProgressBar'
    import rewardLevelService from '@/services/reward-level-service'
    import BadgesComponent from '@/components/BadgesComponent'

    export default {
        name: 'SideBar',
        components: { Avatar, EditIconSvg, SideBarProgressBar, BadgesComponent },
        props: {
            missions: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                rewardNeededPoints: 0,
                windowFeatures: {
                    width: 285,
                    height: 200
                },
                shareOptions: {
                    url: 'https://dev-fraalliance.mpass.ltd/web/welcome',
                    hashtag: '#FRAwards'
                },
                useNativeBehavior: false
            }
        },
        computed: {
            isSidebarProgressInfoEnabled() {
                return this.$store.getters.isSidebarProgressInfoEnabled
            },
            isLeaderboardEnabled() {
                return this.$store.getters.isLeaderboardEnabled
            },
            isBadgesEnabled() {
                return this.$store.getters.isBadgesEnabled
            },
            isRewardsEnabled() {
                return this.$store.getters.isRewardsEnabled
            },
            branding() {
                return this.$store.getters.getBranding
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            playerNickName() {
                return this.player?.nickName ?? this.player?.username
            },
            playerAvailablePoints() {
                return this.player?.totalAvailablePoints
            },
            playerBadges() {
                return this.player?.playerBadges
            },
            showRewardProgressBar() {
                return this.isRewardsEnabled && (this.playerAvailablePoints < this.rewardNeededPoints)
            },
            showGoToRewardsButton() {
                return this.isRewardsEnabled && (this.playerAvailablePoints >= this.rewardNeededPoints)
            },
            currentTotalBadges() {
                let totalBadges = 0
                this.playerBadges.forEach(badge => {
                    totalBadges += badge.count
                })
                return totalBadges
            },
            totalPoints() {
                return this.player.totalScore
            },
            totalMissionCount() {
                return this.$store.getters.getTotalMissions
            },
            ranks() {
                return this.$store.getters.getRanks
            },
            currentPlayerPosition() {
                if (this.ranks.find(rank => rank.id === this.player.id)) {
                    return this.ranks.find(rank => rank.id === this.player.id).rank
                }
                return 0
            },
            completedTasks() {
                return this.$store.getters.getCompletedTasks
            },
            totalTaskCount() {
                return this.$store.getters.getTotalTasks
            },
            completedMissionCount() {
                return this.$store.getters.getCompletedMissions
            }
        },
        created() {
            rewardLevelService.getRewardMinPoints().then(response => {
                this.rewardNeededPoints = response.data.points
                this.$store.commit('setCouponNeededPoints', response.data.points)
            })
        }
    }
</script>

<style lang="scss" scoped>
.profile-side-bar {
    color: var(--white);
    background-color: var(--sidebar-background);
    font-weight: 700;
    max-width: 100%;
    height: 100%;
}

.sidebar-header-mobile {
    justify-content: center;
    padding: 1rem 0;
}

.underlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 2rem;
    background: rgb(0 0 0 / 10%);
    box-shadow: inset 0 5px 5px rgb(0 0 0 / 5%);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
}

.avatar-container {
    background: var(--white);
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
    box-shadow: inset 0 5px 10px rgb(0 0 0 / 25%);
    padding: 8px;
    border-radius: 50%;
    max-width: 110px;
    margin: 1rem auto;
}

.avatar-link {
    align-self: flex-start;
    max-width: 110px;
    display: block;
    position: relative;
}

.edit-icon {
    position: absolute;
    bottom: -0.5rem;
    right: -0.5rem;
    width: unset;
    height: unset;
}

.edit-icon svg {
    width: 100%;
    height: 100%;
}

.user-name {
    color: var(--sidebar-text);
    font-size: 1.35rem;
    height: 2rem;
}

.player-info {
    color: var(--sidebar-text);
    font-size: 1.35rem;
    font-weight: 500;
}

a:hover {
    text-decoration: none;
}

img.logo {
    vertical-align: text-top;
    max-height: 60px;
    max-width: 100%;
}

.profile-side-bar .navigation {
    background-color: var(--sidebar-background);
}

.instructions,
.terms {
    text-decoration: none;
    color: var(--sidebar-text);
    transition: all 0.1s ease-in;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 0.85rem;
}

.copyright {
    font-size: 0.85rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    font-weight: 400;
    gap: 0.25rem;
    color: var(--sidebar-text);
}

.copyright a {
    color: var(--sidebar-text);
}

.copyright a:hover {
    color: var(--sidebar-text);
    text-decoration: none;
}

.player-status {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
    align-items: center;
}

.player-status .value {
    color: var(--sidebar-highlight-text-color);
    font-size: 1.75rem;
}

.player-status .title {
    color: var(--sidebar-highlight-text-color);
    font-weight: 400;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.round-info {
    background-color: var(--sidebar-highlight-color);
    width: 105px;
    height: 105px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
}

.go-to-rewards {
    margin: 1rem auto;
    width: 100%;
    max-width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--sidebar-btn-bg-color);
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
    color: var(--sidebar-btn-text-color);
    border-radius: 20px;
}

.terms-instructions-container {
    width: 100%;
    padding: 0 2rem 1rem;
}

.social-icon {
    width: 100%;
    max-width: 64px;
}
</style>
