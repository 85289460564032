<template>
    <b-container class="prizes-container" fluid>
        <b-row align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-title-first vertical-align mr-2">{{ $t('rewardsRoute.headerTitleFirstWord') }}</span>
            <span class="text-title-second">{{ $t('rewardsRoute.totalPointsLabel') }}: <span class="available-points">{{ totalPoints }}</span></span>
            <span class="text-title-second">{{ $t('rewardsRoute.availablePointsLabel') }}: <span class="available-points">{{ availablePoints }}</span></span>
        </b-row>
        <div class="route-content">
            <div class="reward-coupons-container">
                <reward-card-container
                    v-for="reward in rewardLevels"
                    :key="reward.id"
                    :reward="reward"
                    :available-points="availablePoints"
                />
                <span v-if="rewardLevels.length===0" class="no-rewards-message">{{ $t('rewardsRoute.noRewards') }}</span>
            </div>
            <button
                v-if="rewardLevels.length > 0"
                class="reward-coupons-redeem-button"
                :disabled="isRedeemDisabled"
                @click="redeemRewards"
            >
                {{ $t('rewardsRoute.redeem') }}
            </button>
            <span class="error-message" :class="{'hide': arePointsEnough}">{{ $t('rewardsRoute.notEnoughPointsError') }}</span>
        </div>
    </b-container>
</template>

<script>

    import RewardCardContainer from '@/components/RewardCardContainer'
    import emitter from '@/utils/emitter'
    import moment from 'moment'
    export default {
        components: {
            RewardCardContainer
        },
        data() {
            return {
                showNoPoint: false,
                errorCodes: {
                    INSUFFICIENT_POINTS: this.$t('rewardsRoute.insufficientPointsError'),
                    FAILED_SMS_DELIVERY: this.$t('rewardsRoute.failedSmsDeliveryError'),
                    PLAYER_NOT_LOGGED_IN: this.$t('rewardsRoute.playerNotLoggedInError'),
                    REWARD_LEVEL_NOT_FOUND: this.$t('rewardsRoute.rewardLevelNotFoundError'),
                    REWARD_CODE_NOT_FOUND: this.$t('rewardsRoute.rewardCodeNotFound'),
                    REWARD_NOT_REGISTERED: this.$t('rewardsRoute.rewardNotRegistered'),
                    INVALID_MESSAGE_TEMPLATE: this.$t('rewardsRoute.invalidMessageTemplate'),
                    INVALID_PHONE_NUMBER: this.$t('rewardsRoute.invalidPhoneNumber'),
                    NULL_LIST: this.$t('rewardsRoute.nullListError'),
                    INVALID_DATA: this.$t('rewardsRoute.invalidDataError'),
                    INVALID_PLAYER_DATA: this.$t('rewardsRoute.invalidPlayerDataError')
                }
            }
        },
        computed: {
            isRedeemDisabled() {
                return !this.arePointsEnough || this.cartTotalPoints === 0
            },
            hasMobile() {
                return this.player && this.player.mobile
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            availablePoints() {
                return this.player?.totalAvailablePoints
            },
            totalPoints() {
                return this.player?.totalScore
            },
            cartLength() {
                return this.$store.getters.getRewardCart.length
            },
            arePointsEnough() {
                return this.$store.getters.getRewardCartTotalPoints <= this.availablePoints
            },
            cartTotalPoints() {
                return this.$store.getters.getRewardCartTotalPoints
            },
            rewardLevels() {
                return this.$store.getters.getRewardLevels
            }
        },
        created() {
            this.$store.dispatch('fetchRewardLevels')
        },
        beforeDestroy() {
            this.$store.commit('clearRewardCart')
        },
        methods: {
            isInDateRange(validFrom, validUntil) {
                const today = new Date()
                if (!validFrom && !validUntil) {
                    return true
                }
                if (!validFrom && validUntil) {
                    return moment(validUntil).isAfter(today)
                }
                if (validFrom && !validUntil) {
                    return moment(validFrom).isBefore(today)
                }

                return moment(validFrom).isBefore(today) && moment(validUntil).isAfter(today)
            },
            redeemRewards() {
                if (this.$store.getters.isPreviewMode) {
                    emitter.emit('openRewardsModal', { pointsRedeemed: 100 })
                } else {
                    this.$store.dispatch('redeemRewards', this.errorCodes)
                }
            }
        }
    }

</script>

<style lang="scss" scoped>
.prizes-container {
    color: var(--main-content-text);
    padding: 1rem;
}

.header-top {
    margin-top: 2rem;
    text-align: left;
    border-bottom: 2px solid var(--main-content-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.text-title-first {
    font-weight: 700;
    font-size: 1.75rem;
}

.text-title-second {
    font-weight: 700;
    font-size: 1rem;
}

.available-points {
    color: var(--logo-highlight-color);
}

.route-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 1rem;
}

.reward-coupons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3rem;
    padding: 2rem 0 3rem;
    min-width: 50%;
    border-bottom: 1px solid var(--main-content-text);
    width: 100%;
}

.reward-coupons-redeem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;
}

.reward-coupons-redeem-button {
    background-color: var(--secondary-color);
    color: var(--white);
    border: none;
    border-radius: 4px;
    width: 100%;
    max-width: 160px;
    min-height: 50px;
    font-size: 1rem;
    font-weight: 700;
    margin: 1.5rem auto;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%);
}

.reward-coupons-redeem-button:active {
    transform: scale(0.9);
}

.reward-coupons-redeem-button:disabled {
    background-color: var(--disabled-color);
    cursor: not-allowed;
    transform: none;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.error-message {
    display: block;
    color: red;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1rem;
    transition: all 0.2s ease-in-out;
}

.error-message.hide {
    display: none;
}

.no-rewards-message {
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
    .header-top {
        flex-direction: row;
    }

    .available-points {
        font-size: 1.5rem;
    }

    .reward-coupons-container {
        gap: 1rem;
        justify-content: space-evenly;
    }
}

</style>
