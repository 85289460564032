import restApi from '@/utils/rest-api'
export default {
    getRewardsLevels: () => {
        return restApi.get('reward-levels/available')
    },
    redeemRewards(data) {
        return restApi.post('reward-levels/redeem', [...data])
    },
    getRewardMinPoints: () => {
        return restApi.get('reward-levels/minimum-points')
    }
}
