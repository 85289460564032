<template>
    <div id="progress-tree">
        <div class="title">
            {{ $t('progressTree.header') }}
        </div>
        <b-row class="px-md-3 vertical-align">
            <b-col lg="9" class="col-md-pull-12 p-sm-0 ">
                <div class="progress-wrapper">
                    <div
                        v-for="(level, index) in levels"
                        :key="level.id"
                        lg="2"
                        class="p-0 level"
                    >
                        <b-img
                            v-if="level.inactiveImageUrl"
                            :src="levelImgUrl(level)"
                        />
                        <b-img
                            v-else
                            :src="level.activeImageUrl"
                            :class="{'not-active': levelNotActiveClass(level.minPoints)}"
                        />
                        <span class="mobile-alias" :class="{'not-active': levelValue(level) == 0}"> 0{{ index + 1 }}</span>
                        <b-progress
                            :max="level.maxPoints - level.minPoints"
                            class="progress-tree"
                            :class="{ 'current': currentProgress(level) }"
                        >
                            <b-progress-bar
                                :value="levelValue(level)"
                            />
                        </b-progress>
                        <div
                            class="py-3 level-alias"
                            :class="{ 'current-level-alias': playerScore > level.minPoints }"
                        >{{ level.alias }}</div>
                    </div>
                </div>
            </b-col>
            <b-col class="d-none d-xl-block justify-content-md-center">
                <b-container class="rocket-info-text">
                    <img v-if="theming.progressTreeIcon" :src="theming.progressTreeIcon" alt="" class="rocket-icon">
                    <rocket-svg v-else class="rocket-icon" />
                    <div class="green-text mt-3">{{ $t('progressTree.newTaskText1') }}</div>
                    <div class="">{{ $t('progressTree.newTaskText2') }}</div>
                </b-container>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import missionService from '@/services/mission-service'
    import RocketSvg from '@/components/svg/RocketSVG'

    export default {
        components: {
            RocketSvg
        },
        data() {
            return {
                levels: [],
                playerScore: 0
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            }
        },
        created() {
            missionService.getMissionLevels(this.$route.params.missionId).then(resp => {
                this.levels = resp.data.levels.sort((a, b) => a.position - b.position)
                this.playerScore = resp.data.playerMissionScore
            })
        },
        methods: {
            currentProgress(level) {
                return this.playerScore < level.maxPoints && this.playerScore > level.minPoints
            },
            levelValue(level) {
                if (this.playerScore > level.maxPoints) {
                    return level.maxPoints
                } else if (this.playerScore < level.minPoints) {
                    return 0
                } else {
                    return this.playerScore - level.minPoints
                }
            },
            levelImgUrl(level) {
                if (this.playerScore > 0 && this.playerScore >= level.minPoints) {
                    return level.activeImageUrl
                } else {
                    return level.inactiveImageUrl
                }
            },
            levelNotActiveClass(minPoints) {
                return this.playerScore === 0 || this.playerScore < minPoints
            }
        }
    }
</script>

<style lang="scss" scoped>
.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.progress-wrapper {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
}

.progress {
    border-radius: 1rem;
}

.rocket-icon {
    width: auto;
    height: 50px;
    object-fit: contain;
}

.not-active {
    filter: grayscale(1);
}

#progress-tree {
    text-align: left;
    margin: 2% 1%;
}

#progress-tree .title {
    font-size: 1.35rem;
    font-weight: 700;
    color: var(--main-content-text);
    text-align: center;
}

.rocket-info-text img {
    width: auto;
}

.level img {
    display: none;
}

#progress-tree img {
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    text-align: center;
}

.level {
    width: 100%;
    position: relative;
}

#progress-tree .rocket-info-text {
    text-align: center;
    font-size: 1em;
    font-weight: 700;
    color: var(--main-content-text);
}

.rocket-info-text .green-text {
    color: var(--main-content-text);
}

.mobile-alias {
    position: absolute;
    top: -130%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.85rem;
    font-weight: 700;
    color: var(--progress-bar);
    background-color: var(--white);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.level .level-alias {
    display: none;
}

.level .current-level-alias {
    color: var(--main-content-text);
    font-weight: 700;
}

.progress-mobile .progress-bar {
    background-color: var(--white);
}

@media screen and (min-width: 768px) {
    #progress-tree .title {
        font-size: 1.7rem;
        text-align: left;
    }
}

@media screen and (min-width: 992px) {
    .level img {
        display: block;
        min-height: 91px;
    }

    .mobile-alias {
        display: none;
    }

    .level .level-alias {
        display: block;
        font-size: 0.8em;
        color: var(--main-content-text);
        text-align: center;
    }

    .progress-wrapper {
        margin: 1rem 0;
    }
}

</style>

<style lang="scss">
#progress-tree .progress {
    height: 20px;
}

#progress-tree .progress-bar {
    background: var(--progress-bar);
}

#progress-tree .current .progress-bar {
    transform: translate(-4px, 0);
}

</style>
