import restApi from '@/utils/rest-api'
import store from '@/store'

export default {
    getMissions() {
        return restApi.get('missions/available')
    },
    getMission(id) {
        return restApi.get('missions/' + id)
    },
    awardCertificate(missionId) {
        return restApi.post('missions/' + missionId + '/certificate')
    },
    startTask(missionId, taskId) {
        return restApi.post('missions/' + missionId + '/tasks/' + taskId + '/start')
    },
    getMissionLevels(missionId) {
        return restApi.get('missions/' + missionId + '/levels')
    },
    requestHelpOnTask(missionId, taskId, helpType) {
        return restApi.post('missions/' + missionId + '/tasks/' + taskId + '/help', { helpType }).then(() => {
            store.dispatch('refreshCurrentUser')
        })
    },
    updateFreeTask({ freeGameDTO, score }) {
        return restApi.post('missions/' + freeGameDTO.missionId + '/tasks/' + freeGameDTO.task.id + '/update', {
            points: score,
            finished: true
        })
    },
    updateTask(mission, task, pointsWon, finished) {
        return restApi.post('missions/' + mission.id + '/tasks/' + task.id + '/update', { points: pointsWon, finished })
    }
}
