import rewardLevelService from '@/services/reward-level-service'
import emitter from '@/utils/emitter'
export default {
    state: {
        errorMessage: null,
        isSendingRewards: false,
        showRedeemModal: false,
        rewardCart: [],
        rewardLevels: []
    },
    mutations: {
        setErrorMessage(state, message) {
            state.errorMessage = message
        },
        setIsSendingRewards(state, isSending) {
            state.isSendingRewards = isSending
        },
        setRewardLevels(state, rewardLevels) {
            state.rewardLevels = rewardLevels
        },
        addRewardToCart(state, reward) {
            const rewardIndex = state.rewardCart.findIndex(r => r.rewardLevel.id === reward.rewardLevel.id)

            if (rewardIndex !== -1) {
                state.rewardCart[rewardIndex].amount++
            } else {
                state.rewardCart.push({
                    rewardLevel: {
                        id: reward.rewardLevel.id,
                        points: reward.rewardLevel.points
                    },
                    amount: 1
                })
            }
        },
        removeRewardFromCart(state, reward) {
            const rewardIndex = state.rewardCart.findIndex(r => r.rewardLevel.id === reward.rewardLevel.id)
            if (rewardIndex !== -1) {
                state.rewardCart[rewardIndex].amount--
                if (state.rewardCart[rewardIndex].amount === 0) {
                    state.rewardCart.splice(rewardIndex, 1)
                }
            }
        },
        clearRewardCart(state) {
            state.rewardCart = []
        }
    },
    actions: {
        fetchRewardLevels: async ({ commit }) => {
            try {
                const response = await rewardLevelService.getRewardsLevels()
                commit('setRewardLevels', response.data)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async redeemRewards({ commit, state, dispatch }, errorCodes) {
            commit('setIsSendingRewards', true)
            try {
                const response = await rewardLevelService.redeemRewards(state.rewardCart)
                dispatch('refreshCurrentUser')
                emitter.emit('openRewardsModal', { pointsRedeemed: response.data.pointsRedeemed })
                dispatch('fetchRewardLevels')
                commit('clearRewardCart')
            } catch (error) {
                commit('setErrorMessage', errorCodes[error.response.data.code])
                emitter.emit('openRewardsModal')
            } finally {
                commit('setIsSendingRewards', false)
            }
        }
    }
}
