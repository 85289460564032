import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import player from './modules/player'
import mission from './modules/mission'
import rewards from './modules/rewards'
import freeTask from './modules/freeTask'
import challenges from './modules/challenges'
import challengeSessions from './modules/challengeSessions'
import boardingPass from './modules/boardingPass'
import clusters from './modules/clusters'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        player,
        mission,
        rewards,
        challenges,
        freeTask,
        challengeSessions,
        boardingPass,
        clusters
    },
    getters
})
