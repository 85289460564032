import missionService from '@/services/mission-service'

export default {
    state: {
        totalMissions: 0,
        completedMissions: 0,
        totalTasks: 0,
        completedTasks: 0,
        missions: [],
        missionsLoading: false
    },
    mutations: {
        setMissions(state, missions) {
            state.missions = missions
        },
        setMissionsLoading(state, loading) {
            state.missionsLoading = loading
        },
        setMission(state, mission) {
            const index = state.missions.findIndex(m => m.id === mission.id)
            if (index) {
                state.missions[index] = mission
            }
        },
        setTotalMissions(state, totalMissions) {
            state.totalMissions = totalMissions
        },
        setCompletedMissions(state, completedMissions) {
            state.completedMissions = completedMissions
        },
        setTotalTasks(state, totalTasks) {
            state.totalTasks = totalTasks
        },
        setCompletedTasks(state, completedTasks) {
            state.completedTasks = completedTasks
        }
    },
    actions: {
        async refreshMissions({ commit }) {
            commit('setMissionsLoading', true)

            try {
                const res = await missionService.getMissions()
                let missions = res.data.available
                commit('setMissions', missions)
                commit('setMissionsLoading', false)
                commit('setTotalMissions', res.data.totalMissions)
                commit('setCompletedMissions', res.data.completedMissions)
                commit('setTotalTasks', res.data.totalTasks)
                commit('setCompletedTasks', res.data.completedTasks)
            } catch (error) {
                console.error(error)
                commit('setMissionsLoading', false)
            }
        },
        startTask({ getters, dispatch }, { missionId, taskId }) {
            if (!getters.isPreviewMode && !getters.isTaskCompleted(missionId, taskId)) {
                missionService.startTask(missionId, taskId)
                    .then(() => {
                        console.log('task with ' + taskId + ' was started')
                    }, () => {
                        console.log('task with ' + taskId + ' is already finished or is not next')
                        dispatch('startTask')
                        return true
                    })
            }
        }
    }
}
