<template>
    <div class="mobile-header">
        <div class="avatar-container">
            <router-link
                to="/edit-profile"
                class="avatar-link"
            >
                <avatar
                    :avatar="player.avatar || null"
                />
                <div class="edit-icon">
                    <edit-icon-svg />
                </div>
            </router-link>
        </div>
        <router-link to="/" class="mobile-logo">
            <img :src="branding.primaryLogoUrl" alt="logo">
        </router-link>
        <navigation
            :player="player"
            :missions="displayableMissions"
            class="mobile-navigation"
        />
    </div>
</template>

<script>
    import Avatar from '@/components/Avatar'
    import EditIconSvg from '@/components/svg/EditIconSVG'
    import Navigation from '@/components/Navigation'
    export default {
        components: {
            Avatar,
            EditIconSvg,
            Navigation
        },
        props: {
            missions: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            },
            displayableMissions() {
                return this.missions.filter(mission => {
                    return !mission.locked ||
                        (mission.locked && new Date(mission.startDate) >= new Date())
                })
            }
        }
    }
</script>

<style>
.mobile-navigation .nav-item,
.mobile-navigation .nav-link {
    background-color: var(--navigation-background);
}

.mobile-navigation .b-nav-dropdown .nav-link {
    background: transparent !important;
}
</style>

<style scoped>
.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    position: relative;
    color: var(--sidebar-text);
    background-color: var(--sidebar-background);
    font-weight: 700;
    max-width: 100%;
    height: 100%;
}

.avatar-link {
    align-self: flex-start;
    max-width: 50px;
    display: block;
    position: relative;
}

.edit-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
}

.edit-icon svg {
    width: 100%;
    height: 100%;
}

.mobile-logo {
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

.mobile-logo img {
    width: auto;
    height: 40px;
    max-width: 150px;
}

.mobile-navigation {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 2;
}
</style>
