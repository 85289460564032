<template>
    <div class="redirecting">
        <span>
            Redirecting....
        </span>
        <div v-if="isClusterSelectionOpen" class="cluster-container">
            <cluster-selection
                @login="clusterSelected"
            />
        </div>
    </div>
</template>
<script>
    import ClusterSelection from '@/components/ClusterSelection'
    import playerService from '@/services/player-service'
    export default {
        name: 'TokenLoginRoute',
        components: {
            ClusterSelection
        },
        data() {
            return {
                isClusterSelectionOpen: false
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            availableClusters() {
                return this.$store.getters.getAvailableClusters
            },
            code() {
                return this.$route.params.code
            }
        },
        created() {
            this.initiateRedirect()
        },
        methods: {
            async initiateRedirect() {
                if (this.isLoggedIn) {
                    this.goToHome()
                    return
                }
                await this.$store.dispatch('fetchAvailableClusters')
                try {
                    if (this.code && this.availableClusters === 0) {
                        this.handleLogin()
                    } else if (this.availableClusters?.length > 0) {
                        this.isClusterSelectionOpen = true
                    }
                } catch (error) {
                    this.isClusterSelectionOpen = false
                    if (error.response.data?.code === 'NO_PENDING_EMAIL_VERIFICATION') {
                        this.goToHome()
                    }
                }
            },
            async handleLogin() {
                try {
                    const tokenRes = await playerService.tokenLogin(this.code)
                    this.$store.commit('login', tokenRes.data.token)
                    this.goToHome()
                } catch (error) {
                    this.goToHome()
                    this.isClusterSelectionOpen = false
                }
            },
            clusterSelected(clusterId) {
                if (!clusterId) return
                this.$store.commit('setSelectedCluster', clusterId)
                this.handleLogin()
            },
            goToHome() {
                this.$router.push({ path: '/home' })
            }
        }
    }
</script>

<style scoped>
.redirecting {
    color: var(--main-content-text);
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
}

.cluster-container {
    height: 50vh;
    width: 100%;
    max-width: 600px;
    position: relative;
}

</style>
